<template>
  <div class="settings-content-wrapper">
    <div v-if="!isLoading && bankAccount">
      <h1 v-if="id">Edit Bank Account</h1>
      <h1 v-else>Create Bank Account</h1>
      <hr>
      <b-field class="modal-label" label="Label">
        <b-input v-model="bankAccount.label" type="text" placeholder="e.g. Main Trading Account; Savings Account"/>
      </b-field>
      <div v-if="countryCode === 'AU'">
        <b-field class="modal-label" label="Name On Account">
          <b-input v-model="bankAccount.accountDetails.nameOnAccount" type="text" placeholder="Bettys Beauty Pty Ltd"/>
        </b-field>
        <b-field grouped>
          <b-field label="BSB" class="modal-label" expanded>
            <b-input v-model="bankAccount.accountDetails.bsb" type="text" placeholder="064-404"/>
          </b-field>
          <b-field label="Account Number" expanded class="modal-label">
            <b-input v-model="bankAccount.accountDetails.accountNumber" type="text" placeholder="1234 5678"/>
          </b-field>
        </b-field>
      </div>
      <div v-if="countryCode === 'US'">
        <b-field class="modal-label" label="Name On Account">
          <b-input v-model="bankAccount.accountDetails.nameOnAccount" type="text" placeholder="Bettys Beauty Pty Ltd"/>
        </b-field>
        <b-field grouped>
          <b-field label="ACH Routing Number" class="modal-label" expanded>
            <b-input v-model="bankAccount.accountDetails.ACHRoutingNumber" type="text" placeholder="121140399"/>
          </b-field>
          <b-field label="Account Number" expanded class="modal-label">
            <b-input v-model="bankAccount.accountDetails.accountNumber" type="text" placeholder="12345678"/>
          </b-field>
        </b-field>
      </div>
      <hr>
      <div class="level is-mobile">
        <div class="level-left"></div>
        <div class="level-right">
          <div class="level-item">
            <a class="button is-light" @click="cancel">Cancel</a>
          </div>
          <div class="level-item">
            <button class="button is-primary" @click="save">Save</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BankAccountEdit',
  data () {
    return {
      isLoading: false,
      bankAccount: {
        label: '',
        accountDetails: {}
      }
    }
  },
  computed: {
    bankInstitutions () {
      return this.$store.getters['settings/bankInstitutions']
    },
    countryCode () {
      return this.$store.getters['account/countryCode']
    }
  },
  methods: {
    cancel () {
      this.$router.push({ name: 'settings-home' })
    },
    reloadBankAccount () {
      if (this.id) {
        this.isLoading = true
        this.$store.dispatch('settings/fetchBankAccount', this.id)
          .then((bankAccount) => {
            this.bankAccount = bankAccount
            this.isLoading = false
          })
      }
    },
    save () {
      this.$store.dispatch('settings/saveBankAccount', this.bankAccount)
        .then((response) => {
          this.$buefy.toast.open({
            message: 'Bank account saved.',
            type: 'is-success'
          })
          this.$store.dispatch('account/fetch')
            .then(() => {
              this.$router.push({ name: 'settings-home' })
            })
        })
        .catch(err => {
          const errors = err.response.data.errors
          if (errors) {
            Object.keys(errors).forEach((errorKey) => {
              this.$buefy.toast.open({
                message: errors[errorKey][0],
                type: 'is-danger',
                duration: 5000,
                queue: false
              })
            })
          } else {
            this.$buefy.toast.open({
              message: `Error: ${err.message}`,
              type: 'is-danger',
              duration: 4000,
              queue: false
            })
          }
        })
    }
  },
  mounted () {
    this.reloadBankAccount()
    this.$store.dispatch('settings/fetchBankInstitutions')
  },
  components: {},
  props: ['id']
}
</script>
